import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <div className="contact-div">
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Contact"
        />
        <link rel="cannonical" href="/contact"></link>
        <meta
          name="keywords"
          content="Contact"
        />
      </Helmet>
      <text>
        If you have comments for the page or questions have arisen.
        <br></br>
        <br></br>
        Please feel free to contact me via email.
        <br></br>
        <br></br>
        alex.aaron.furtner@gmail.com
        <br></br>
        <br></br>
      </text>
    </div>
  );
};

export default Contact;
