import { Link } from 'react-router-dom'

const Right = () => {
    return (
        <div className="right">
            <Link to="/SortingAlgorithm">
           <h5>Sorting algorithms</h5>
            An excursion to the implementation and analysis of various well-known sorting algorithms.
            </Link>
        </div>
    );
}
 
export default Right;