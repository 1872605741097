import { useState } from "react";
import { Helmet } from 'react-helmet';

const ExtendedEuclideanAlgorithm = () => {
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [submit, setSubmit] = useState(false);
  const [gcd, setGcd] = useState(null);
  const [X, setX] = useState(null);
  const [Y, setY] = useState(null);

  const handleFirstChange = (e) => {
    setFirst(e.target.value);
    setSubmit(false);
  };
  const handleSecondChange = (e) => {
    setSecond(e.target.value);
    setSubmit(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    setFirst(Math.abs(first));
    setSecond(Math.abs(second));

    let a = Math.abs(first);
    let b = Math.abs(second);
    let oldy,
      q,
      y = 0;
    let oldx,
      r,
      x = 1;
    let u = 0;
    let v = 1;

    while (b !== 0) {
      q = Math.floor(a / b);
      r = a % b;

      a = b;
      b = r;
      oldx = x;
      oldy = y;
      x = u;
      y = v;
      u = oldx - q * u;
      v = oldy - q * v;
    }
    setGcd(a);
    setX(x);
    setY(y);
  };

  return (
    <div className="eea-div">
      <Helmet>
        <title>Extended euclidean algorithm</title>
        <meta
          name="description"
          content="The extended euclidean algorithm"
        />
        <link rel="cannonical" href="/algorithms/1"></link>
        <meta
          name="keywords"
          content="Algorithm, Extended euclidean algorithm"
        />
      </Helmet>
      <h2>Extended euclidean algorithm</h2>
      <form onSubmit={handleSubmit}>
        <div className="eea-first">
          <label>First number: </label>
          <input
            type="number"
            required
            value={first}
            onChange={(e) => handleFirstChange(e)}
          ></input>
        </div>
        <div className="eea-second">
          <label>Second number: </label>
          <input
            type="number"
            required
            value={second}
            onChange={(e) => handleSecondChange(e)}
          ></input>
        </div>
        <button>Calculate</button>
      </form>
      <div className="eea-answer">
        {submit && (
          <text>
            gcd({first}, {second}) = {gcd} = {first} * {X} + {second} * {Y}
          </text>
        )}
      </div>
    </div>
  );
};

export default ExtendedEuclideanAlgorithm;
