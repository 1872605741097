import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="notfound-div">
      <h1 className="doesn-not-exist">
        unfortunately this site does not exist...
      </h1>
      <Link to="/">
        <p>Back to the Homescreen</p>
      </Link>
    </div>
  );
};

export default NotFound;
