import { useState } from "react";
import { Helmet } from 'react-helmet';

const SquareAndMultiply = () => {
  const [A, setA] = useState("");
  const [B, setB] = useState("");
  const [N, setN] = useState("");
  const [submit, setSubmit] = useState(false);
  const [answer, setAnswer] = useState(null);

  const handleAChange = (e) => {
    setA(e.target.value);
    setSubmit(false);
  };
  const handleBChange = (e) => {
    setB(e.target.value);
    setSubmit(false);
  };
  const handleNChange = (e) => {
    setN(e.target.value);
    setSubmit(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    //a^b mod n
    let x = 1;
    let a = A;
    let b = B;
    let n = N;

    while (b > 0) {
      if (b % 2 === 1) {
        x = (x * a) % n;
        b--;
      } else {
        a = Math.pow(a, 2) % n;
        b = b / 2;
      }
    }
    setAnswer(x);
  };

  return (
    <div className="sam-div">
      <Helmet>
        <title>Square and multiply</title>
        <meta name="description" content="The algorithm square and multiply" />
        <link rel="cannonical" href="/algorithms/2"></link>
        <meta
          name="keywords"
          content="Algorithm, square and multiply, Exponention by squaring"
        />
      </Helmet>
      <h2>Square-and-Multiply</h2>
      <div className="sam-example">
        Example: b^e (mod n) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp;
      </div>
      <form onSubmit={handleSubmit}>
        <div className="sam-first">
          <label>base (b): &nbsp;</label>
          <input
            type="number"
            required
            value={A}
            onChange={(e) => handleAChange(e)}
          ></input>
        </div>
        <div className="sam-second">
          <label>exponent (e): </label>
          <input
            type="number"
            required
            value={B}
            onChange={(e) => handleBChange(e)}
          ></input>
        </div>
        <div className="sam-third">
          <label>n:&nbsp; </label>
          <input
            type="number"
            required
            value={N}
            onChange={(e) => handleNChange(e)}
          ></input>
        </div>
        <button>Calculate</button>
      </form>
      <div className="sam-answer">{submit && <text>{answer}</text>}</div>
    </div>
  );
};

export default SquareAndMultiply;
