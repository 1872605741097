import { Helmet } from 'react-helmet';

const WhatIsAnAlgorithm = () => {
  return (
    <div className="wiaa-div">
      <Helmet>
        <title>What is an Algorithm</title>
        <meta
          name="description"
          content="Explained what an algorithm actually is"
        />
        <link rel="cannonical" href="/WhatIsAnAlgorithm"></link>
        <meta
          name="keywords"
          content="Algorithm, simple"
        />
      </Helmet>
      <h2>The term is everywhere...</h2>
      <h2>but what is an algorithm simply explained</h2>

      <p>
        You can think of an algorithm as a finite set of instructions, by
        following these instructions you can solve a specific problem step by
        step.
        <br></br>
        <br></br>A common but good fitting analogy for an algorithm is a Cake
        recipe. The goal is to bake a cake, the ingredients are basically
        entered into the algorithm. These input values ​​are called parameters.
        The individual baking steps are then followed using the parameters, this
        is the actual algorithm.
        <br></br>
        <br></br>
        The term is most commonly used in computer science or mathematics, but
        even there they describe the same thing. They are usually used to carry
        out specific calculations or tasks.
        <br></br>
        Algorithms can be used for all sorts of tasks, from simple things like
        sorting a list of names to complex things like recognizing faces in
        pictures or recommending products based on your shopping history.
        They're used in everything from your phone to your computer to the
        websites you visit.
        <br></br>
        <br></br>
        One of the key things about algorithms is that they're designed to be
        efficient. This means they're meant to solve the problem or perform the
        task in the shortest amount of time possible. It's like finding the
        quickest way to get from your house to the store. You might take a few
        different routes to get there, but the algorithm would find the one that
        gets you there the fastest.
        <br></br>
        <br></br>
        Feel free to check out the algorithm details, to see what set of
        instrutions or mathematical formulas is behind the algorithms on this
        page.
      </p>
      <div className="pie">
        <text>
          <h4>pseudocode: apple_pie</h4>
          Input: 300g flour, 180g butter, 150g sugar, salt, 8 apples, cinnamon
          <br></br>
          Output: apple_pie <br></br>- dough = mix(flour, butter, sugar, salt){" "}
          <br></br>- dough.knead() <br></br>- dough = dough.bake(7 min){" "}
          <br></br>- while (|apples| != 0) <br></br>
          ----- pie = distribute(dough, apples, cinnamon)<br></br>- pie.bake(60
          min) <br></br>- Output: pie <br></br>
        </text>
      </div>
    </div>
  );
};

export default WhatIsAnAlgorithm;
