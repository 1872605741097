import { Link } from 'react-router-dom'

const Navbar = () => {
    return (
        <nav className="navbar">
            <Link to="/"><img className="navbar-logo" src="Logo.png" alt=""></img></Link>
            <div className="navbar-links">
                <Link to="/" className="navbar-home">Home</Link>
                <Link to="/details">Algorithm details</Link> 
                <Link to="/contact">Contact</Link>
            </div>
        </nav>
    );
}
 
export default Navbar;