import { Helmet } from 'react-helmet';

const SortingAlgorithm = () => {
  return (
    <div className="sorting-div">
      <Helmet>
        <title>Sorting algorithms</title>
        <meta
          name="description"
          content="Important sorting algorithms and how they are implemented"
        />
        <link rel="cannonical" href="/SortingAlgorithm"></link>
        <meta name="keywords" content="Algorithm, Sortingalgorithm, Complexity, Insertionsort, BubbleSort, Mergesort, Quicksort, In-place, Divide and conquer" />
      </Helmet>
      <div className="header">
        <h2>
          The following algorithms will all take an array of numbers as input
          value and return the array sorted
        </h2>
        <p>|A| will refer to the length of an array A</p>
        <br></br>
        <br></br>
        <label>
          Download a Python script that contains the given sorting algorithms:{" "}
        </label>
        <br></br>
        <a
          className="py-download"
          href="SortingAlgorithms.py"
          download="SortingAlgorithms.py"
        >
          ---SortingAlgorithms.py---
        </a>
      </div>

      <div className="div1">
        <h2>insertion_sort</h2>
        <p>
          This Algorithm sorts the array one element at a time, by dividing the
          array into two parts, one sorted the other one unsorted. Initially,
          the sorted sublist contains only the first element of the array. The
          algorithm then iterates through the unsorted sublist, inserting each
          element into its correct position within the sorted sublist.
        </p>

        <text>
          <h4>pseudocode:</h4>
          Input: array A <br></br>
          Output: array A (sorted) <br></br>- for i = 1; to i = |A|-1: <br></br>
          ----- dummy = A[i] <br></br>
          ----- j = i <br></br>
          ----- while (j {">"} 0 AND A[j-1] {">"} dummy) <br></br>
          ---------- A[j] = A[j-1] <br></br>
          ---------- j = j - 1 <br></br>
          ----- A[j] = dummy <br></br>
        </text>
        <br></br>
        <text>
          <h4>
            time complexity (runtime in relation to the input length (n)):
          </h4>
          - best-case: O(n^2) <br></br>- worst-case: O(n^2) <br></br>
        </text>
        <text>
          <h4>space complexity (amount of memory space used):</h4>- O(1)
          [in-place sorting algorithm]<br></br>
        </text>
      </div>

      <div className="div2">
        <h2>bubble_sort</h2>
        <p>
          This Algorithm repeatedly swaps adjacent elements if they are in the
          wrong order until the array is sorted.
        </p>
        <text>
          <h4>pseudocode:</h4>
          Input: array A <br></br>
          Output: array A (sorted) <br></br>- for i = |A|; to i = 1: <br></br>
          ----- for j = 1; to j = i-1: <br></br>
          ---------- if (A[j] {">"} A[j+1]): <br></br>
          --------------- temp = A[j] <br></br>
          --------------- A[j] = A[j+1] <br></br>
          --------------- A[j+1] = temp <br></br>- Output: A <br></br>
        </text>
        <br></br>
        <text>
          <h4>
            time complexity (runtime in relation to the input length (n)):
          </h4>
          - best-case: O(n^2) [can be reduced to O(n) by checking if the array
          is already sorted after every iteration]<br></br>- worst-case: O(n^2){" "}
          <br></br>
        </text>
        <text>
          <h4>space complexity (amount of memory space used):</h4>- O(1)
          [in-place sorting algorithm]<br></br>
        </text>
      </div>

      <div className="div3">
        <h2>merge_sort</h2>
        <p>
          This algorithm is a divide-and-conquer algorithm. It works by
          recursively dividing the input array into two halves until each
          sub-array contains only one element. Then, it merges these sub-arrays
          back together in a sorted manner.
        </p>
        <text>
          <h4>pseudocode:</h4>
          Input: array A <br></br>
          Output: array A (sorted) <br></br>- if (|A| {"<"}= 1) <br></br>
          ----- Output: a <br></br>- mid = |A| // 2 <br></br>- left =
          A[every_value_on_the_left_side_of_mid] <br></br>- right =
          A[every_value_on_the_right_side_of_mid] <br></br>- Output: merge(left,
          right) <br></br>
          <br></br>
          merge-function:<br></br>
          Input: array left, array right <br></br>
          Output: array result (sorted) <br></br>- result = [] <br></br>- i = 0{" "}
          <br></br>- j = 0 <br></br>- while (i {"<"} |left| AND j {"<"} |right|){" "}
          <br></br>
          ----- if (left[i] {"<"}= right[j]) <br></br>
          ---------- result.append(left[i]) <br></br>
          ---------- i = i + 1 <br></br>
          ----- else <br></br>
          ---------- result.append(right[j]) <br></br>
          ---------- j = j + 1 <br></br>- result = result +
          left[starting_from_i]<br></br>- result = result +
          right[starting_from_j]<br></br>- Output: result <br></br>
        </text>
        <br></br>
        <text>
          <h4>
            time complexity (runtime in relation to the input length (n)):
          </h4>
          - best-case: O(n * log(n)) <br></br>- worst-case: O(n * log(n)){" "}
          <br></br>
        </text>
        <text>
          <h4>space complexity (amount of memory space used):</h4>- O(n){" "}
          <br></br>
        </text>
      </div>

      <div className="div4">
        <h2>quick_sort</h2>
        <p>
          This algorithm is a divide-and-conquer algorithm. By partitioning the
          array around the pivot element, the algorithm ensures that the pivot
          element is in its correct sorted position, and the remaining elements
          are divided into two sub-arrays that can be sorted independently. The
          algorithm moves all elements less then the pivot element to the left
          and elements greater than the pivot element to the right
        </p>
        <text>
          <h4>pseudocode:</h4>
          Input: array A <br></br>
          Output: array A (sorted) <br></br>- if (|A| {"<"}= 1) <br></br>
          ----- Output: A <br></br>- else <br></br>
          ----- pivot = random_element_of_A <br></br>
          ----- left = [all_elements_in_A_less_then_pivot] <br></br>
          ----- mid = [all_elements_in_A_equal_to_pivot] <br></br>
          ----- right = [all_elements_in_A_greater_then_pivot] <br></br>
          ----- Output: quick_sort(left) + middle + quick_sort(right) <br></br>
        </text>
        <br></br>
        <text>
          <h4>
            time complexity (runtime in relation to the input length (n)):
          </h4>
          - best-case: O(n * log(n)) <br></br>- worst-case: O(n^2) [occurs when
          the array is already sorted]<br></br>
        </text>
        <text>
          <h4>space complexity (amount of memory space used):</h4>- O(log(n)){" "}
          <br></br>
        </text>
      </div>
      <text>
        <br></br>
        annotation: <br></br>
        <br></br>- In-place sorting algorithm, means the algorithm does not
        require any additional storage space beyond what is needed for the
        original input.<br></br> <br></br>- Divide-and-conquer sorting
        algorithm, means the algorithm breaks down the problem into sub-problems
        with the same structure in order to solve these smaller problems easier.
        The solution to the main problem is then rebuilt from the solutions of
        the smaller problems.
      </text>
    </div>
  );
};

export default SortingAlgorithm;
