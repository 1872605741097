import { Link } from 'react-router-dom'

const Left = () => {
    return (
        <div className="left">
           <Link to="WhatIsAnAlgorithm">
            <h5>What is an Alogrithm ?!</h5>
            Simply explained what an algorithm is and where it is used.
            </Link>
        </div>
    );
}
 
export default Left;