import { useState } from "react";
import { Helmet } from 'react-helmet';

const GaussianElimination = () => {
  let dummy_matrix = [];
  let list = [];
  let matrixx = [];

  const [error, setError] = useState("");
  const [answer, setAnswer] = useState([]);
  const [matrix, setMatrix] = useState([]);
  const [submitMatrix, setSubmitMatrix] = useState(false);

  const [rows, setRows] = useState(2);
  const [columns, setColumns] = useState(2);

  let id = 0;
  const inputs = [];

  for (let j = 0; j < rows; j = j + 1) {
    for (let i = 0; i < columns; i = i + 1) {
      inputs.push(
        <input
          a-key={id}
          type="number"
          defaultValue={0}
          onChange={(e) => {
            dummy_matrix = matrix; /*Save what hasen't been changed*/
            setSubmitMatrix(false); /*Stop Rendering Answer*/
            dummy_matrix[e.target.getAttribute("a-key")] = Number(
              e.target.value
            );
          }}
        ></input>
      );
      id = id + 1;
    }
    inputs.push(<br></br>);
  }

  const handleRowsChange = (e) => {
    setRows(e.target.value);
    setMatrix([]); /*Reset Matrix*/
    setSubmitMatrix(false);
  };
  const handleColumnsChange = (e) => {
    setColumns(e.target.value);
    setMatrix([]); /*Reset Matrix*/
    setSubmitMatrix(false);
  };

  /*Format answer*/
  const showAnswer = (answer) => {
    let labels = [];
    for (let a = 0; a < rows; a = a + 1) {
      labels.push(<label>[</label>);
      for (let b = 0; b < columns; b = b + 1) {
        if (
          answer[a][b] >= 10000 ||
          answer[a][b] <= -1000 ||
          (answer[a][b] % 1 !== 0 && answer[a][b] < -10)
        ) {
          labels.push(
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{answer[a][b]}&nbsp;</label>
          );
        } else if (
          answer[a][b] >= 1000 ||
          answer[a][b] <= -100 ||
          (answer[a][b] % 1 !== 0 && answer[a][b] < 0)
        ) {
          labels.push(
            <label>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{answer[a][b]}&nbsp;&nbsp;
            </label>
          );
        } else if (
          answer[a][b] >= 100 ||
          answer[a][b] <= -10 ||
          answer[a][b] % 1 !== 0
        ) {
          labels.push(
            <label>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{answer[a][b]}&nbsp;&nbsp;&nbsp;
            </label>
          );
        } else if (answer[a][b] >= 10 || answer[a][b] < 0) {
          labels.push(
            <label>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{answer[a][b]}
              &nbsp;&nbsp;&nbsp;&nbsp;
            </label>
          );
        } else {
          labels.push(
            <label>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{answer[a][b]}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </label>
          );
        }
      }
      labels.push(<label>]</label>);
      labels.push(<br></br>);
    }
    return <div>{labels}</div>;
  };

  /*row-wise operations */
  function RowSwap(A, k, l) {
    /*Rows k and l will be swapped*/
    for (let i = 0; i < columns; i = i + 1) {
      let temp = A[k][i];
      A[k][i] = A[l][i];
      A[l][i] = temp;
    }
    return A;
  }
  function RowScale(A, k, scale) {
    /*Entries of row k will be multiplied by scale*/
    for (let i = 0; i < columns; i = i + 1) {
      A[k][i] = A[k][i] * scale;
    }
    return A;
  }
  function RowAdd(A, k, l, scale) {
    /*Row l will be modified by k multiplied by scale*/
    for (let i = 0; i < columns; i = i + 1) {
      A[l][i] = A[l][i] + A[k][i] * scale;
    }
    return A;
  }

  /*Pivoting*/
  function Pivot(A, pivot_Element, pivot_Column, pivot_Row) {
    if (pivot_Element === 0) {
      return A;
    }
    if (pivot_Element !== 1) {
      A = RowScale(A, pivot_Row, 1 / pivot_Element);
    }
    for (let i = 0; i < rows; i = i + 1) {
      if (i !== pivot_Row) {
        A = RowAdd(A, pivot_Row, i, -1 * A[i][pivot_Column]);
      }
    }
    return A;
  }

  const handleMatrixSubmit = (e) => {
    e.preventDefault();

    /*Not changed values will be filled up with zeros*/
    for (let i = 0; i < rows * columns; i = i + 1) {
      if (dummy_matrix[i] == null) {
        dummy_matrix[i] = 0;
      }
      /*Another dummy Array*/
      list[i] = dummy_matrix[i];
    }

    /*1D-dummy_matrix will be converted into a 2D-matrix*/
    while (list.length > 0) {
      matrixx.push(list.splice(0, columns));
    }

    /*Algorithm*/
    if (columns < 2 || rows < 2) {
      setError("ERROR-NO-MATRIX");
      setMatrix(dummy_matrix);
      setAnswer(matrixx);
      setSubmitMatrix(false);
      return "ERROR-NO-MATRIX";
    }

    /*First Pivot*/
    let pivot_row = 0;
    let pivot_column = 0;
    let pivot_element = matrixx[pivot_row][pivot_column];

    if (pivot_element === 0) {
      for (let a = 1; a < rows; a = a + 1) {
        matrixx = RowSwap(matrixx, 0, a);
        pivot_element = matrixx[pivot_row][pivot_column];
        if (pivot_element !== 0) {
          break;
        }
      }
    }
    if (pivot_element === 0) {
      setError("ERROR-ONLY-ZEROS-IN-ROW-ONE");
      setMatrix(dummy_matrix);
      setAnswer(matrixx);
      setSubmitMatrix(false);
      return "ERROR-ONLY-ZEROS-IN-ROW-ONE";
    }

    /*Algorithm*/

    console.log(pivot_element);
    let end_rows = rows;
    let end_colums = columns;
    while (end_colums !== 0 && end_rows !== 0) {
      /*pivot is equal to zero --> one space to the right*/
      if (pivot_element === 0) {
        /*new*/
        if (pivot_element === 0) {
          for (let a = 1; a < rows; a = a + 1) {
            matrixx = RowSwap(matrixx, 0, a);
            pivot_element = matrixx[pivot_row][pivot_column];
            if (pivot_element !== 0) {
              break;
            }
          }
        }
        if (pivot_element === 0) {
          /*end*/
          pivot_column = pivot_column + 1;
          pivot_element = matrixx[pivot_row][pivot_column];
        }

        end_colums = end_colums - 1;
      } else {
        /*pivot is not equal to zero --> one diagonal down*/
        matrixx = Pivot(matrixx, pivot_element, pivot_column, pivot_row);
        pivot_row = pivot_row + 1;
        pivot_column = pivot_column + 1;
        if (pivot_row < rows && pivot_column < columns) {
          pivot_element = matrixx[pivot_row][pivot_column];
        }

        end_colums = end_colums - 1;
        end_rows = end_rows - 1;
      }
    }

    console.log(matrixx);

    /*Round the values*/
    for (let ro = 0; ro < rows; ro = ro + 1) {
      for (let co = 0; co < columns; co = co + 1) {
        if (matrixx[ro][co] % 1 !== 0) {
          matrixx[ro][co] = matrixx[ro][co].toFixed(2);
        }
      }
    }

    /*Safe the not changed*/
    setMatrix(dummy_matrix);
    /*show answer*/
    setError("");
    setAnswer(matrixx);
    setSubmitMatrix(true);
  };

  /*Needs to be a Matrix and do not use expressions like 03, also reset the page if problems arise, leaf rows with only zeros out*/

  return (
    <div className="ge-div">
      <Helmet>
        <title>Gaussian elimination</title>
        <meta name="description" content="Gaussian elemination" />
        <link rel="cannonical" href="/algorithms/3"></link>
        <meta
          name="keywords"
          content="Algorithm, Gaussian elemination, system of linear equations, matrix, Upper triangular matrix"
        />
      </Helmet>
      <label className="rules">
        The input needs to be a matrix / do not use superfluous zeros (02 or
        003) / leaf out rows and columns with only zeros / reset the page after
        changing dimension of your matrix or if problems arise{" "}
      </label>
      <div className="rows-columns-div">
        <label>rows:</label>
        <input
          type="number"
          defaultValue={2}
          onChange={(e) => {
            handleRowsChange(e);
          }}
        ></input>
        <label>columns:</label>
        <input
          type="number"
          defaultValue={2}
          onChange={(e) => {
            handleColumnsChange(e);
          }}
        ></input>
      </div>

      <form onSubmit={handleMatrixSubmit}>
        <div className="ge-matrix">{inputs}</div>
        <button>Calculate</button>
        <div className="ns-answer">
          {submitMatrix && showAnswer(answer)}
          {!submitMatrix && error}
        </div>
      </form>
    </div>
  );
};

export default GaussianElimination;
