import { useState } from "react";
import { Helmet } from 'react-helmet';

const NumberSystems = () => {
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [submit, setSubmit] = useState(false);
  const [answer, setAnswer] = useState([]);

  const handleFirstChange = (e) => {
    setFirst(e.target.value);
    setSubmit(false);
  };
  const handleSecondChange = (e) => {
    setSecond(e.target.value);
    setSubmit(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmit(true);

    let a = first;
    let b = second;
    let l = [];

    while (a !== 0) {
      l.unshift(a % b);
      l.unshift(" ");
      a = Math.floor(a / b);
    }
    setAnswer(l);
  };

  return (
    <div className="ns-div">
      <Helmet>
        <title>Number systems</title>
        <meta name="description" content="Convert a decimal number into another number system" />
        <link rel="cannonical" href="/algorithms/4"></link>
        <meta
          name="keywords"
          content="Algorithm, Number system, Convert, Binary"
        />
      </Helmet>
      <h2>Number systems</h2>
      <form onSubmit={handleSubmit}>
        <div className="ns-first">
          <label>Decimal number you want to convert: </label>
          <input
            type="number"
            required
            value={first}
            onChange={(e) => handleFirstChange(e)}
          ></input>
        </div>
        <div className="ns-second">
          <label>The number system to convert into: </label>
          <input
            className="ns-input2"
            type="number"
            min="2"
            required
            value={second}
            onChange={(e) => handleSecondChange(e)}
          ></input>
        </div>
        <button>Calculate</button>
      </form>
      <div className="ns-answer">
        {submit && (
          <text>
            [ {answer} ]<sub className="ns-sub">{second}</sub>{" "}
          </text>
        )}
      </div>
    </div>
  );
};

export default NumberSystems;
