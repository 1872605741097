import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from "./Components/Navbar";
import Home from "./Components/Home";
import NotFound from './Components/NotFound';
import ExtendedEuclideanAlgorithm from './Components/ExtendedEuclideanAlgorithm';
import SquareAndMultiply from './Components/SquareAndMultiply';
import NumberSystems from './Components/NumberSystems';
import AlgoDetails from './Components/AlgoDetails';
import Contact from './Components/Contact';
import GaussianElimination from './Components/GaussianElimination';
import Left from './Components/Left';
import Right from './Components/Right';
import WhatIsAnAlgorithm from './Components/WhatIsanAlgorithm';
import SortingAlgorithm from './Components/SortingAlgorithm';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <Router>
      <Helmet>
        <title>algorithmhelper.com</title>
        <meta 
          name="description"
          content="Use and understand mathematical algorithms! &nbsp; A website that provides and explains useful mathematical and computer science-related algorithms. &nbsp; Check your results and enjoy learning in an easy way. &nbsp; powered by P-NP-Studios"    
        />
        <meta 
        name='keywords'
        content='Algorithm, Maths, Computer science, Extended euclidean algorithm, Gaussian elimination'
        />
      </Helmet>

      <div className="App">
        <Navbar></Navbar> 
        <div className="content">
          <Switch>
            <Route exact path="/"> 
               <Left></Left> 
               <Home></Home> 
               <Right></Right>
            </Route>
            <Route exact path="/details"> 
              <AlgoDetails></AlgoDetails>
            </Route>
            <Route exact path="/contact"> 
              <Contact></Contact>
            </Route>
            <Route exact path="/WhatIsAnAlgorithm"> 
              <WhatIsAnAlgorithm></WhatIsAnAlgorithm>
            </Route>
            <Route exact path="/SortingAlgorithm"> 
              <SortingAlgorithm></SortingAlgorithm>
            </Route>

            {/*Algorithems:*/}
            <Route exact path="/algorithms/1"> 
              <ExtendedEuclideanAlgorithm></ExtendedEuclideanAlgorithm>
            </Route>
            <Route exact path="/algorithms/2"> 
              <SquareAndMultiply></SquareAndMultiply>
            </Route>
            <Route exact path="/algorithms/3"> 
              <GaussianElimination></GaussianElimination>
            </Route>
            <Route exact path="/algorithms/4"> 
              <NumberSystems></NumberSystems>
            </Route>

            {/*Page Not Found*/}
            <Route path="*"> 
            <NotFound></NotFound>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
