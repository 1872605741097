import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Helmet } from 'react-helmet';

const AlgoDetails = () => {
  return (
    <div className="details-div">
      <Helmet>
        <title>Algorithm details</title>
        <meta name="description" content="More information on the algorithms on this site" />
        <link rel="cannonical" href="/details"></link>
        <meta
          name="keywords"
          content="Algorithm, Maths, Computer science, Extended euclidean algorithm, Gaussian elimination"
        />
      </Helmet>
      <div className="div1">
        <Link to="/algorithems/1">
          <h2>Extended euclidean algorithm</h2>
        </Link>
        <p>
          An algorithm to identify the greatest common divisor of two numbers (a
          and b), but also to calculate two coefficients (x and y) that satisfy
          the equation gcd(a, b) = a * x + b * y<br></br>
          <br></br>
          Furthermore if the two numbers are coprime, meaning the greatest
          common divisor of a and b is equal to 1, this algorithm is also
          capable of calculating the modular mutiplicative inverse of a modulo b
          and b modulo a.<br></br>
          This is highly useful in cryptography and for example used in
          derivation of key-pairs in the RSA encryption method.
        </p>
        <text>
          <h4>pseudocode:</h4>
          Input: a, b ∈ ℤ <br></br>
          Output: c = gcd(a, b) and x, y ∈ ℤ <br></br>- a = |a|, b = |b|{" "}
          <br></br>- x = 1, y = 0, u = 0, v = 1 <br></br>- while (b != 0) do{" "}
          <br></br>
          ----- q = a div b, r = a mod b <br></br>
          ----- a = b, b = r <br></br>
          ----- λ = x - q * u <br></br>
          ----- x = u, u = λ <br></br>
          ----- ε = y - q * v <br></br>
          ----- y = v, v = ε <br></br>- end (while) <br></br>- c = a <br></br>-
          Output: c, x, y <br></br>
        </text>
        <text className="update-text">
          <h4>Update formulas:</h4>
          --{">"} q<sub>new</sub> = a<sub>old</sub> div b<sub>old</sub>{" "}
          <br></br>
          --{">"} r<sub>new</sub> = a<sub>old</sub> mod b<sub>old</sub>{" "}
          <br></br>
          --{">"} u<sub>new</sub> = x<sub>old</sub> - q<sub>new</sub> * u
          <sub>old</sub> <br></br>
          --{">"} v<sub>new</sub> = y<sub>old</sub> - q<sub>new</sub> * v
          <sub>old</sub> <br></br>
          --{">"} x<sub>new</sub> = u<sub>old</sub> <br></br>
          --{">"} y<sub>new</sub> = v<sub>old</sub> <br></br>
          --{">"} a<sub>new</sub> = b<sub>old</sub> <br></br>
          --{">"} b<sub>new</sub> = r<sub>old</sub>
        </text>
        <text>
          <h4>Example for calculating the multiplicative inverse:</h4>
          gcd(500, 11) = 1 = 500 * -2 + 11 * 91
          <br></br>
          Our goal now is to find the multiplicative inverse of 500 (mod 11) and
          11 (mod 500)
          <br></br> <br></br>
          500 (mod 11): <br></br>
          gcd(500, 11) = 1 = 500 * -2 + 11 * 91 = 500 * -2 = 1 (mod 11){" "}
          <br></br>
          --{">"} This means that -2 = 9 (mod 11) is the multiplicative inverse
          of 500 (mod 11)
          <br></br> <br></br>
          11 (mod 500): <br></br>
          gcd(11, 500) = 1 = 11 * 91 + 500 * -2 = 11 * 91 = 1 (mod 500){" "}
          <br></br>
          --{">"} This means that 91 is the multiplicative inverse of 11 (mod
          500)
        </text>
      </div>
      <div className="div2">
        <Link to="/algorithems/2">
          <h2>Square-and-Multiply</h2>
        </Link>
        <p>
          An algorithm for quickly computing positive integer powers of a
          number, modulo another number.
        </p>
        <text>
          <h4>pseudocode:</h4>
          Input: a ∈ ℤ and b, n ∈ ℕ <br></br>
          Output: x = a^b (mod n) <br></br>- x = 1 <br></br>- while (b {">"} 0)
          do <br></br>
          ----- if b mod 2 = 1 <br></br>
          ---------- x = x * a mod n <br></br>
          ---------- b = b - 1 <br></br>
          ----- end if <br></br>
          ----- a = a^2 mod n <br></br>
          ----- b = b div 2 <br></br>- end (while) <br></br>- Output: x{" "}
          <br></br>
        </text>
      </div>
      <div className="div3">
        <Link to="/algorithems/3">
          <h2>Gaussian elimination</h2>
        </Link>
        <p>
          An algorithm for solving systems of linear equations, by transforming
          a matrix of coefficients to an upper triangular matrix. <br></br>
          This is done by repeatedly applying three types of elementary row
          operations.<br></br>
          <br></br>
          ---{">"} Swappingt two rows<br></br>
          ---{">"} Multiplying a row by a number that is not equal to zero
          <br></br>
          ---{">"} Adding a multiple of one row to another<br></br>
          <br></br>
          These elementary row operations can be applyed without changing the
          solution set of our system.<br></br>
          <br></br>
          The algorithm goes through the matrix and pivots in each row if
          possible until there are as many unit vectors as possible in the
          matrix.<br></br>
          [Pivoting describes the process of using elementary row operations to
          obtain a unit vector in the row in which the pivot is taking place]
          <br></br>
          <br></br>
          This algorithm has many applications. Of course, as discussed, the
          solution of systems of linear equations, but also the calculation of
          the determinants of matrices or the inverse of a matrix. <br></br>
          <br></br>
          The inverse is calculated, for example, by writing your matrix next to
          the unit matrix and then running the algorithm
          <br></br>
          <br></br>
          [-1&nbsp;&nbsp;3&nbsp;&nbsp; 1 | 1&nbsp;&nbsp;0&nbsp;&nbsp;0
          ]&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
          1&nbsp;&nbsp;0&nbsp;&nbsp;0 | -0.42&nbsp;&nbsp;&nbsp;
          0.17&nbsp;&nbsp;&nbsp;&nbsp; 0.08&nbsp;] <br></br>[
          3&nbsp;&nbsp;6&nbsp;&nbsp;3 | 0&nbsp;&nbsp;1&nbsp;&nbsp;0 ]&nbsp; ---
          {">"} &nbsp;[ 0&nbsp;&nbsp;1&nbsp;&nbsp;0 |
          &nbsp;&nbsp;0.17&nbsp;&nbsp;&nbsp;
          0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 0.17&nbsp;&nbsp;]{" "}
          <br></br>[ 1&nbsp;&nbsp;3&nbsp;&nbsp;-1 | 0&nbsp;&nbsp;0&nbsp;&nbsp;1
          ]&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[
          0&nbsp;&nbsp;0&nbsp;&nbsp;1 |
          &nbsp;0.08&nbsp;&nbsp;&nbsp;&nbsp;0.17&nbsp;&nbsp;&nbsp; -0.42&nbsp;]{" "}
          <br></br>
          <br></br>
          <h4>pseudocode:</h4>
          Input: matrix A <br></br>
          Output: matrix A (A is an upper triangular matrix) <br></br>- end_row
          = A.number_of_rows() <br></br>- end_column = A.number_of_columns(){" "}
          <br></br>- pivot_row = 0 <br></br>- pivot_column = 0 <br></br>- pivot
          = A[pivot_row][pivot_column] <br></br>- while (end_row != 0 AND
          end_column != 0) <br></br>
          ----- if (pivot == 0) <br></br>
          ---------- while (pivot == 0) <br></br>
          --------------- swapRows() <br></br>
          --------------- setNewPivot() <br></br>
          ---------- if (pivot == 0) <br></br>
          --------------- pivot_column = pivot_column + 1 <br></br>
          --------------- pivot = A[pivot_row][pivot_column] <br></br>
          --------------- end_column = end_column - 1 <br></br>
          ----- else <br></br>
          ---------- A.pivot(pivot) <br></br>
          ---------- pivot_row = pivot_row + 1 <br></br>
          ---------- pivot_column = pivot_column + 1 <br></br>
          ---------- if (pivot_row {"<"} A.get_number_rows() AND pivot_column{" "}
          {"<"} A.get_number_columns()) <br></br>
          --------------- pivot = A[pivot_row][pivot_column] <br></br>
          ---------- end_row = end_row - 1 <br></br>
          ---------- end_column = end_column - 1 <br></br>- Output A <br></br>
        </p>
      </div>
      <div className="div4">
        <Link to="/algorithems/4">
          <h2>Number systems</h2>
        </Link>
        <p>
          An algorithm that converts a decimal number into another number
          system.
        </p>
        <text>
          <h4>pseudocode:</h4>
          Input: n, b ∈ ℕ <br></br>
          Output: L <br></br>- x = n div b <br></br>- α = n mod b <br></br>- L
          [insert_at_the_beginning(α)] <br></br>- while (x != 0) do <br></br>
          ----- y = x div b <br></br>
          ----- α = x mod b <br></br>
          ----- x = y <br></br>
          ----- L [insert_at_the_beginning(α)] <br></br>- end (while) <br></br>-
          Output: L <br></br>
        </text>
      </div>
    </div>
  );
};

export default AlgoDetails;
