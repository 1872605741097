import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Home = () => {
  const options = [
    {
      title: "Extended euclidean algorithm",
      details:
        "An algorithm to identify the greatest common divisor of two numbers (a and b), but also to calculate two coefficients (x and y) that satisfy the equation gcd(a, b) = a * x + b * y",
      id: 1,
    },
    {
      title: "Square-and-multiply",
      details:
        "An algorithm for quickly computing positive integer powers of a number, modulo another number (b^e mod n)",
      id: 2,
    },
    {
      title: "Gaussian elimination",
      details:
        "An algorithm for solving systems of linear equations, by transforming a matrix of coefficients to an upper triangular matrix",
      id: 3,
    },
    {
      title: "Number systems",
      details:
        "An algorithm that converts a decimal number into another number system",
      id: 4,
    },
  ];

  return (
    <div className="options">
      <Helmet>
        <title>algorithmhelper.com</title>
        <meta
          name="description"
          content="Homescreen to choose your service"
        />
        <link rel="cannonical" href="/"></link>
        <meta
          name="keywords"
          content="Algorithm, Maths, Computer science, Extended euclidean algorithm, Gaussian elimination"
        />
      </Helmet>

      {options.map((option) => (
        <li key={option.id}>
          <div className="option-preview">
            <Link to={`/algorithms/${option.id}`}>
              <h2>{option.title}</h2>
              <p>{option.details}</p>
            </Link>
          </div>
        </li>
      ))}
    </div>
  );
};

export default Home;
